import React, { Component } from 'react'
import Alert from '../../Component/Alert'
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import t from '../../Lang/Index'
import { signInWithEmailAndPassword } from "firebase/auth";
import { getAuth, signInWithRedirect, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";

export default class LoginForm extends Component {

    constructor() {
        super();
        this.state = {
            isInsert: true,
            email: '',
            password: '',
            messages: []
        };

        this.onClick_BtnLogin = this.onClick_BtnLogin.bind(this);
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onClick_BtnLogin = (e) => {
        e.preventDefault();

        if (!this.validate())
            return;

        var messages = [];
        this.setState({ messages, isInsert: false });

        var self = this;

        debugger;

        signInWithEmailAndPassword(getAuth(), this.state.email, this.state.password)
            .then(authUser => {
                //window.localStorage.setItem('apiToken', response.data.token);
                window.localStorage.setItem('idToken', authUser._tokenResponse.idToken);
                self.setState({ isRedirectToHome: true });

            })
            .catch(error => {
                window.localStorage.setItem('idToken', '');
                var message = error.message.replace("Firebase: ", "");
                messages.push(message);
                self.setState({ messages, isInsert: true });
            });
    }

    validate = () => {
        var messages = [];
        if (this.state.email.length == 0)
            messages.push("Please enter an email");
        //messages.push("لطفا ایمیل را تکمیل نمایید");

        if (this.state.email.length > 0 && !isEmail(this.state.email))
            messages.push("Please enter valid email")
        //messages.push("ایمیل را به صورت صحیح درج نمایید");

        if (this.state.password.length == 0)
            messages.push("Please enter your password");

        this.setState({ messages: messages });

        return messages.length == 0;

    }

    render() {

        if (this.state.isRedirectToHome) {
            return <Redirect to={'/'} />
        }

        return (

            <form role="form">                    
                <div className="input-group input-group-outline mb-3">
                    <label className="form-label">Email</label>
                    <input type="email" name="email" onChange={this.onChange} className="form-control" />
                </div>
                <div className="input-group input-group-outline mb-3">
                    <label className="form-label">Password</label>
                    <input type="password" name="password" onChange={this.onChange} className="form-control" />
                </div>
                {this.state.messages.length > 0 &&
                        <Alert messages={this.state.messages}></Alert>}
                <div className="text-center">
                    <button type="button" className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
                        onClick={this.onClick_BtnLogin}>Sign In</button>                    
                </div>
            </form>

        )
    }
}
