import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';
import cellButton from '../../Component/cellButton';
import cellButtonReport from '../../Component/CellButtonReport';
import CellBtnIcon from '../CellBtnIcon';
import UserDefaultCustomerText from '../../Component/Calibration/UserDefaultCustomerText';
import CellButtonResult from '../../Component/CellButtonResults';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import cellBtnTimesheetMonthlyPrint from './Component/CellBtnTimesheetMonthlyPrint'
import cellBtnTimesheetMonthlyItem from './Component/CellBtnTimesheetDailyItem'
import cellBtnUpload from './Component/CellBtnUpload'
import cellBtnDownload from './Component/CellBtnDownload'
import FileUploaderGraph from "../../Component/Timesheet/FileUploaderGraph";
import fileDownload from 'js-file-download'
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'


const TimesheetMonthly = (props) => {

    const PAGE_TITLE = "Monthly Timesheet";

    const [data, setData] = React.useState([]);
    const [messages, setMessages] = React.useState([]);
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(10);
    const [total, setTotal] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [alertDialog, setAlertDialog] = React.useState(false);
    const [uploadDialog, setUploadDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(false);
    const [uploadDialogCloseButtonVisible, setUploadDialogCloseButtonVisible] = React.useState(true);
    const [isRedirecToInsert, setIsRedirecToInsert] = React.useState(false);
    const [filterDialog,setFilterDialog] = React.useState(false);
    const [F_title,setF_title] = React.useState('');
    const [F_titleOrTimesheetMonthlyCode,setF_titleOrTimesheetMonthlyCode] = React.useState('');

    const [sort, setSort] = React.useState([
        { field: 'id', dir: 'desc' }
    ]);

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const [isRedirectToTimesheetMonthlyItem, setIsRedirectToTimesheetMonthlyItem] = React.useState(false);



    React.useEffect(() => {
        //TODO FetchMonthlyTimeSheet
        fetchData();
        document.title = PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }, []);


    const getFilterStr = () => {

        if (!F_titleOrTimesheetMonthlyCode)
            return "";

        var result = " Search: ";

        if (F_titleOrTimesheetMonthlyCode)
            result = result + "TimesheetMonthly title Or Code contains:" + F_titleOrTimesheetMonthlyCode;

        return result;
    }

    const showfilterDialog = () => {
        this.setState({ filterDialog: true });
    }

    const onFilter = () => {
        this.fetchData();
        this.setState({ filterDialog: false });
    }

    const removeFilter = () => {
        this.setState({
            F_titleOrTimesheetMonthlyCode: '',
        }, () => { this.fetchData() });
    }

    const pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take,
        }, () => {
            this.fetchData();
        });

    }

    const onSortChange = (e) => {
        this.setState({
            sort: e.sort,
        }, () => {
            this.fetchData();
        });
    }

    const fetchData = () => {
        debugger;
        var self = this;

        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "TimesheetMonthly/GetTimesheetMonthlyListAdmin/?Skip=" + skip
                + "&Take=" + take
                + "&SortField=" + (sort[0] ? sort[0].field : "")
                + "&SortDir=" + (sort[0] ? sort[0].dir : "")
                + "&F_title=" + F_title
                + "&F_titleOrTimesheetMonthlyCode=" + F_titleOrTimesheetMonthlyCode
                + "&time=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            ).then(response => {
                setData(response.data.rowModels);
                setTotal(response.data.total);                
            }).catch(error => {
                console.log(error);
            })
        });
    }


    const insert = () => {
        setIsRedirecToInsert(true);        
    }

    const edit = (dataItem) => {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ isRedirectToEdit: true })
    }

    const selectTimeSheetItem = (dataItem) => {
        setSelectedId(dataItem.id);
        setIsRedirectToTimesheetMonthlyItem(true);
    }

    const deleteConfirm = (dataItem) => {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ deleteDialog: true })
    }


    const hideDialogs = () => {
        setDeleteDialog(false);
        setAlertDialog(false);
        setUploadDialog(false);
    }

    const showUploadDialog = (dataItem) => {
        setSelectedId(dataItem.id);
        setUploadDialog(true);
    }

    const onUploadInit = () => {
        setUploadDialogCloseButtonVisible(false);
    };

    const onUploadSuccess = () => {        
        setUploadDialogCloseButtonVisible(true);
        fetchData();
    };

    const onUploadError = () => {        
        setUploadDialogCloseButtonVisible(true);
    };

    const download = (dataItem) =>{
        const selectedId = dataItem.id;        

        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "TimesheetMonthly/download/?entityId=" + selectedId
                + "&time=" + new Date().getTime(),
                {
                    responseType: 'blob',
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            ).then(response => {                
                fileDownload(response.data, response.headers["x-filename"]);
            }).catch(error => {                
                console.log(error);
            })
        });        
    }

    const deleteItem = () => {

        let messages = [];
        let self = this;
        debugger;
        Axios.post(Config.API_URL + "CertificateOfCalibration/delete",
            {
                Id: self.state.buildingRef,
            },
            { headers: { 'Authorization': 'Bearer ' + this.props.user.accessToken } })
            .then(response => {
                //let id = response.data;
                this.setState({ deleteDialog: false });
                this.fetchData();
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    messages.push("Failed to connect to server")
                }
                self.setState({ messages, alertDialog: true });
            });
    }



    if (isRedirecToInsert) {
        return <Redirect to={'/TimesheetMonthly/Add'} />
    }

    if (isRedirectToTimesheetMonthlyItem) {
        return <Redirect to={'/TimesheetMonthlyItems/' + selectedId} />
    }

    return (


        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

            {/* Navbar */}
            <NavBar />
            <div className="container-fluid py-4">


                <div className="col-12">
                    <div className="card mb-4">
                        <div class="card-header p-3 pt-2">
                            <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                <i class="fa fas fa-calendar-check fa-fw" aria-hidden="true"></i>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 class="mb-0">{PAGE_TITLE}</h6>
                                </div>

                            </div>
                        </div>


                        <div className="card-body px-0 pt-0 pb-2">

                            <Grid data={data} skip={skip} take={take}
                                total={total} sort={sort} pageSize={pageSize}
                                onPageChange={pageChange}
                                onSortChange={onSortChange}
                                sortable pageable resizable
                            >

                                {/* <GridToolbar>
                                        <Button iconClass="fa fa-filter"
                                            style={{ 'margin-left': '5px' }}
                                            onClick={this.showfilterDialog}>Filter</Button>
                                        <Button iconClass="fa fa-times"
                                            style={{ 'margin-left': '5px' }}
                                            onClick={this.removeFilter}>Remove</Button>
                                        {this.getFilterStr()}
                                    </GridToolbar> */}

                                <GridToolbar>
                                    <Button onClick={insert} >New Timesheet Monthly</Button>
                                </GridToolbar>

                                {/* <Column field="vCustomerName" title="Customer" width="150px" className="NoWrap"  sortable/> */}
                                <Column field="id" title="Id" width="60px" className="NoWrap" />
                                <Column cell={cellBtnTimesheetMonthlyItem(selectTimeSheetItem)} width="110px" />
                                <Column cell={cellBtnTimesheetMonthlyPrint()} width="110px" />
                                <Column cell={cellBtnUpload(showUploadDialog)} width="110px"  />                                
                                <Column cell={cellBtnDownload(download)} width="110px" />
                                {/* <Column cell={CellDeleteButton(this.deleteConfirm)}  width="110px"   />  */}
                                {/* Edit */}

                                <Column field="vUserDisplayTitle" title="Inspector" width="250px" className="NoWrap" />
                                <Column field="projectTitle" title="Project Name" width="250px" className="NoWrap" />
                                <Column field="clientTitle" title="Client Name" width="250px" className="NoWrap" />
                                <Column field="siteLocationTitle" title="Site & Location" width="250px" className="NoWrap" />
                                <Column field="createBy" title="Create By" width="200px" className="NoWrap" />
                                <Column field="vCreateDate" title="Create Date" width="200px" className="NoWrap" />
                            </Grid>
                            <div>



                                {
                                    //Make Delete As Component
                                    deleteDialog &&
                                    <Dialog
                                        title="Delete"
                                        onClose={this.hideDialogs}
                                    >
                                        <div>
                                            Are you sure you want to delete this item?
                                            {/* آیا برای حذف {deleteTitle} مطمئن هستید؟ */}
                                        </div>

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={this.delete}
                                            >
                                                Yes
                                            </button>
                                            <button
                                                className="k-button"
                                                onClick={this.hideDialogs}

                                            >
                                                No
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>
                                }

                                {
                                    //Make Delete As Component
                                    alertDialog > 0 &&
                                    <Dialog
                                        title="Alert"
                                        onClose={this.hideDialogs} >

                                        {messages.length > 0 &&
                                            <ul>
                                                {messages.map((message) => {
                                                    return (
                                                        <li>{message}</li>
                                                    )
                                                })}
                                            </ul>
                                        }

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={this.hideDialogs}
                                            >
                                                Close
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>
                                }

                                {filterDialog &&
                                    <Dialog
                                        title="Filter"
                                        onClose={() => { this.setState({ filterDialog: false }) }}
                                        width={250}
                                    >
                                        <div>


                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="input-group input-group-static">
                                                        <label>TimesheetMonthly Title or Code</label>
                                                        <input type="text" class="form-control" name="F_titleOrTimesheetMonthlyCode"
                                                            onChange={this.onChange} value={F_titleOrTimesheetMonthlyCode} />
                                                    </div>
                                                </div>
                                            </div>


                                            {/* <div class="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Status
                                                    </label>
                                                    <input type="text" class="form-control" name="F_title"
                                                        onChange={this.onChange} value={F_title} />
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Created By
                                                    </label>
                                                    <input type="text" class="form-control" name="F_title"
                                                        onChange={this.onChange} value={F_title} />
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Parent TimesheetMonthly
                                                    </label>
                                                    <input type="text" class="form-control" name="F_title"
                                                        onChange={this.onChange} value={F_title} />
                                                </div> */}
                                        </div>

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={this.onFilter}
                                            >
                                                OK
                                            </button>
                                            <button
                                                className="k-button"
                                                onClick={() => { this.setState({ filterDialog: false }) }}
                                            >
                                                Cancel
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>}

                                {/* {
    loading && <Dialog>
        <i class='fa fa-spinner fa-spin '></i> Loading...
    </Dialog>
} */}

                            </div>

                            {                                    
                                    uploadDialog &&
                                    <Dialog
                                        title="Upload"
                                        onClose={hideDialogs}
                                        closeIcon={uploadDialogCloseButtonVisible}
                                    >
                                        <div>

                                        <FileUploaderGraph
                                            onUploadSuccess={onUploadSuccess}
                                            onUploadError={onUploadError}
                                            onUploadInit={onUploadInit}
                                            entityId={selectedId}
                                            user={user} />                                          
                                        </div>

                                        <DialogActionsBar>
                                            {/* <button
                                                    className="k-button k-primary"
                                                    onClick={delete}
                                                >
                                                    Yes
                                                </button> */}
                                            {/* <button
                                                className="k-button"
                                                onClick={hideDialogs}

                                            >
                                                No
                                            </button> */}
                                        </DialogActionsBar>
                                    </Dialog>
                                }




                        </div>
                    </div>
                </div>




            </div>

            <Footer />
        </main>


    )
}

export default TimesheetMonthly;
