import React from 'react'
import { version } from '../../package.json';


const Footer = (prop) => {

    return (
        <footer className="footer py-4  ">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-lg-between">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <div className="copyright text-center text-sm text-muted text-lg-start">
                © {new Date().getFullYear()}, Powered By <a href="https://citadel-engineering.com" className="font-weight-bold" target="_blank">Citadel Engineering Company</a> IT Department (Version {version})               
            
              </div>
            </div>
            <div className="col-lg-6">
              <ul className="nav nav-footer justify-content-center justify-content-lg-end">
              <li className="nav-item">            
                  <a href="https://citadel-engineering.com/about-us/" className="nav-link pe-0 text-muted" target="_blank">About Us</a>
                </li>
                <li className="nav-item">            
                  <a href="https://citadel-engineering.com/our-services/" className="nav-link pe-0 text-muted" target="_blank">Services</a>
                </li>
                <li className="nav-item">            
                  <a href="https://citadel-engineering.com/contact-us/" className="nav-link pe-0 text-muted" target="_blank">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
}

export default Footer;
