import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';
import cellButton from '../../Component/cellButton';
import cellButtonReport from '../../Component/CellButtonReport';
import CellBtnIcon from '../CellBtnIcon';
import UserDefaultCustomerText from '../../Component/Calibration/UserDefaultCustomerText';
import CellButtonResult from '../../Component/CellButtonResults';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import CellBtnRevisionItems from '../Doc/Component/CellBtnRevisionItems'

export default class PoDoc extends Component {

    constructor() {
        super();

        this.state = {
            data: [], loading: true, messages: [],
            skip: 0,
            take: 10,
            total: 0,
            pageSize: 10,
            deleteDialog: false,
            sort: [
                { field: 'id', dir: 'desc' }
            ],
            filterDialog: false,
            F_DocCode:'',
            F_PoDesc:'',
            F_ReferenceNumber:'',
            F_VendorName:'',
            isRedirectToCalibrationResult: false
        };

        this.fetchData = this.fetchData.bind(this);
        this.insert = this.insert.bind(this);
        this.edit = this.edit.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.delete = this.delete.bind(this);
        this.hideDialogs = this.hideDialogs.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.selectResults = this.selectResults.bind(this);
        this.onChange = this.onChange.bind(this);
        this.selectRevisionItem = this.selectRevisionItem.bind(this);        

        this.PAGE_TITLE = "Po Doc Control";
    }

    getFilterStr = () => {        
        var result = "";

        if (this.state.F_DocCode)
            result = result + "Doc Code contains:" + this.state.F_DocCode;
        
        if (this.state.F_PoDesc)
        result = result + " Po Description contains:" + this.state.F_PoDesc;

        if (this.state.F_ReferenceNumber)
        result = result + " Refrence Number contains:" + this.state.F_ReferenceNumber;

        if (this.state.F_VendorName)
        result = result + " VendorName contains:" + this.state.F_VendorName;

        if (result)
           result = " Search: " + result;

        return result;
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    showfilterDialog = () => {
        this.setState({ filterDialog: true });
    }

    onFilter = () => {

        this.fetchData();
        this.setState({ filterDialog: false });
    }

    removeFilter = () => {
        this.setState({
            F_DocCode:'',
            F_PoDesc:'',
            F_ReferenceNumber:'',
            F_VendorName:'',
        }, () => { this.fetchData() });
    }

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take,
        }, () => {
            this.fetchData();
        });

    }

    onSortChange = (e) => {
        this.setState({
            sort: e.sort,
        }, () => {
            this.fetchData();
        });
    }


    fetchData() {
        debugger;
        var self = this;
        this.setState({ loading: true });

        getIdToken(this.props.user).then((idToken) => {
            Axios.get(Config.API_URL + "PoDoc/GetPoDocListAdmin/?Skip=" + this.state.skip
                + "&Take=" + this.state.take
                + "&SortField=" + (this.state.sort[0] ? this.state.sort[0].field : "")
                + "&SortDir=" + (this.state.sort[0] ? this.state.sort[0].dir : "")
                + "&F_DocCode=" + this.state.F_DocCode
                + "&F_PoDesc=" + this.state.F_PoDesc
                + "&F_ReferenceNumber=" + this.state.F_ReferenceNumber
                + "&F_VendorName=" + this.state.F_VendorName
                + "&time=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            ).then(response => {
                self.setState({
                    data: response.data.rowModels,
                    total: response.data.total,
                    loading: false
                })
            }).catch(error => {
                console.log(error);
            })
        });
    }


    componentWillMount() {
        this.fetchData()
    }

    componentDidMount() {
        document.title = this.PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }

    insert = () => {
        this.setState({ isRedirecToInsert: true });
    }

    edit(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ isRedirectToEdit: true })
    }

    selectResults = (dataItem) => {
        this.setState({ certificateOfCalibrationRef: dataItem.id })
        this.setState({ vReportTemplateSimpleCode: dataItem.vReportTemplateSimpleCode })
        this.setState({ isRedirectToCalibrationResult: true })
    }


    deleteConfirm(dataItem) {
        this.setState({ buildingRef: dataItem.id })
        this.setState({ deleteDialog: true })
    }

    hideDialogs() {
        this.setState({ deleteDialog: false });
        this.setState({ alertDialog: false });
    }

    selectRevisionItem = (dataItem) => {
        this.setState({ selectedId: dataItem.id })        
        this.setState({ isRedirectToRevision: true })
    }   

    delete() {

        let messages = [];
        let self = this;
        debugger;
        Axios.post(Config.API_URL + "CertificateOfCalibration/delete",
            {
                Id: self.state.buildingRef,
            },
            { headers: { 'Authorization': 'Bearer ' + this.props.user.accessToken } })
            .then(response => {
                //let id = response.data;
                this.setState({ deleteDialog: false });
                this.fetchData();
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    messages.push("Failed to connect to server")
                }
                self.setState({ messages, alertDialog: true });
            });
    }

    render() {

        if (this.state.isRedirecToInsert) {
            return <Redirect to={'/PoDoc/Add'} />
        }

        if (this.state.isRedirectToRevision) {
            return <Redirect to={'/PoDocRevision/' + this.state.selectedId} />
        }

        return (


            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                {/* Navbar */}
                <NavBar />
                <div className="container-fluid py-4">


                    <div className="col-12">
                        <div className="card mb-4">
                            <div class="card-header p-3 pt-2">
                                <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                    <i class="fa fa fa-file-alt fa-fw" aria-hidden="true"></i>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 class="mb-0">{this.PAGE_TITLE}</h6>
                                    </div>

                                </div>
                            </div>


                            <div className="card-body px-0 pt-0 pb-2">

                                <Grid data={this.state.data} skip={this.state.skip} take={this.state.take}
                                    total={this.state.total} sort={this.state.sort} pageSize={this.state.pageSize}
                                    onPageChange={this.pageChange}
                                    onSortChange={this.onSortChange}
                                    sortable pageable resizable
                                >

                                    <GridToolbar>
                                        <Button iconClass="fa fa-filter"
                                            style={{ 'margin-left': '5px' }}
                                            onClick={this.showfilterDialog}>Filter</Button>
                                        <Button iconClass="fa fa-times"
                                            style={{ 'margin-left': '5px' }}
                                            onClick={this.removeFilter}>Remove</Button>
                                        {this.getFilterStr()}
                                    </GridToolbar>

                                    <GridToolbar>
                                        <Button onClick={this.insert} >New Po Document</Button>
                                    </GridToolbar>

                                    {/* <Column field="vCustomerName" title="Customer" width="150px" className="NoWrap"  sortable/> */}
                                    <Column field="id" title="Id" width="60px" className="NoWrap" />
                                    <Column cell={CellBtnRevisionItems(this.selectRevisionItem)}  width="110px"   />
                                    <Column field="docCode" title="Document Code" width="250px" className="NoWrap" />
                                    <Column field="referenceNumber" title="Reference Number" width="150px" className="NoWrap" />
                                    <Column field="vendorName" title="Vendor Name" width="300px" className="NoWrap" />
                                    <Column field="poDesc" title="PO Description" width="500px" className="NoWrap" />
                                    <Column title="Last Revision">
                                        <Column field="vLastRevisionNumber" title="Number" width="80px" className="NoWrap" />
                                        <Column field="vLastRevisionDate" title="Date" width="120px" className="NoWrap" />
                                        <Column field="vLastStatusTitle" title="Status" width="100px" className="NoWrap" />
                                    </Column>
                                    <Column field="createBy" title="Create By" width="200px" className="NoWrap" />
                                    <Column field="vCreateDate" title="Create Date" width="200px" className="NoWrap" />
                                    <Column field="vParentDocCode" title="Parent Code" width="250px" className="NoWrap" />
                                    {/* <Column field="vCustomerName" title="Customer Name" width="150px"  className="NoWrap"/>  */}
                                    {/* <Column field="vCalibrationUserTitle" title="Calibration User" width="150px"  className="NoWrap"/>
    <Column field="vReviewUserTitle" title="Review User" width="120px"  className="NoWrap"/>  */}
                                    {/* <Column cell={CellButtonResult(this.selectResults)} width="110px" /> */}
                                    {/* <Column cell={CellBtnIcon(this.selectMasterEquipments,'tools')}  width="70px" />                     */}
                                    {/* <Column cell={cellButtonReport()} width="110px" /> */}
                                    {/* <Column cell={CellDeleteButton(this.deleteConfirm)} width="110px" /> */}
                                </Grid>
                                <div>



                                    {
                                        //Make Delete As Component
                                        this.state.deleteDialog &&
                                        <Dialog
                                            title="Delete"
                                            onClose={this.hideDialogs}
                                        >
                                            <div>
                                                Are you sure you want to delete this item?
                                                {/* آیا برای حذف {this.state.deleteTitle} مطمئن هستید؟ */}
                                            </div>

                                            <DialogActionsBar>
                                                <button
                                                    className="k-button k-primary"
                                                    onClick={this.delete}
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    className="k-button"
                                                    onClick={this.hideDialogs}

                                                >
                                                    No
                                                </button>
                                            </DialogActionsBar>
                                        </Dialog>
                                    }

                                    {
                                        //Make Delete As Component
                                        this.state.alertDialog > 0 &&
                                        <Dialog
                                            title="Alert"
                                            onClose={this.hideDialogs} >

                                            {this.state.messages.lPoth > 0 &&
                                                <ul>
                                                    {this.state.messages.map((message) => {
                                                        return (
                                                            <li>{message}</li>
                                                        )
                                                    })}
                                                </ul>
                                            }

                                            <DialogActionsBar>
                                                <button
                                                    className="k-button k-primary"
                                                    onClick={this.hideDialogs}
                                                >
                                                    Close
                                                </button>
                                            </DialogActionsBar>
                                        </Dialog>
                                    }

                                    {this.state.filterDialog &&
                                        <Dialog
                                            title="Filter"
                                            onClose={() => { this.setState({ filterDialog: false }) }}
                                            width={250}
                                        >
                                            <div>


                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="input-group input-group-static">
                                                            <label>Code</label>
                                                            <input type="text" class="form-control" name="F_DocCode"
                                                                onChange={this.onChange} value={this.state.F_DocCode} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="input-group input-group-static">
                                                            <label>Po Description</label>
                                                            <input type="text" class="form-control" name="F_PoDesc"
                                                                onChange={this.onChange} value={this.state.F_PoDesc} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="input-group input-group-static">
                                                            <label>Reference Number</label>
                                                            <input type="text" class="form-control" name="F_ReferenceNumber"
                                                                onChange={this.onChange} value={this.state.F_ReferenceNumber} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="input-group input-group-static">
                                                            <label>Vendor Name</label>
                                                            <input type="text" class="form-control" name="F_VendorName"
                                                                onChange={this.onChange} value={this.state.F_VendorName} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <DialogActionsBar>
                                                <button
                                                    className="k-button k-primary"
                                                    onClick={this.onFilter}
                                                >
                                                    OK
                                                </button>
                                                <button
                                                    className="k-button"
                                                    onClick={() => { this.setState({ filterDialog: false }) }}
                                                >
                                                    Cancel
                                                </button>
                                            </DialogActionsBar>
                                        </Dialog>}

                                    {/* {
    this.state.loading && <Dialog>
        <i class='fa fa-spinner fa-spin '></i> Loading...
    </Dialog>
} */}

                                </div>




                            </div>
                        </div>
                    </div>




                </div>

                <Footer />
            </main>


        )


    }
}
