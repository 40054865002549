import React, {useRef} from 'react'
import axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../Alert';

const FileUploader = (props) => {
    const fileInput = useRef(null)
    const [error, setError] = React.useState([]);
    const [message, setMessage] = React.useState([]);

    const handleFileInput = (e) => {
        // handle validations        
        setMessage("");
        setError("");
        
        const file = e.target.files[0];

        if (!file)
        {
            setError("File Not Selected");   
            props.onUploadError();      
            return;     
        }

        const maxSize = 4 * 1048576;                     

        if (file.size > maxSize)
        {
            setError("File size cannot exceed more than 4MB");   
            props.onUploadError();      
            return;     
        }

        var fileExtension = file.name.split('.').pop();

        if (!(fileExtension == "pdf" || fileExtension == "jpg"  || fileExtension == "xlsx"  || fileExtension == "docx" ))
        {
            setError("Only pdf,jpg,xlsx,docx are allowed.");   
            props.onUploadError();      
            return;     
        }


        submitForm(file);
    }

    //Show the file name 
    //Disable upload button / show uploading
    //Enable after error success 
    //Check Error

    //TODO Clear button

    const submitForm = (selectedFile) => {
        debugger;
        const formData = new FormData();
        formData.append("name", "test");
        formData.append("files", selectedFile);
        const UPLOAD_URL = Config.API_URL + "FileTbl/Upload/"
        axios
          .post(UPLOAD_URL, formData,{ 
              headers: { 
               'Authorization': 'Bearer ' + props.user.accessToken
              } 
            })
          .then(response => {
            let id = response.data;
            setMessage("Uploaded Successfully FileNumber:" + id)
            props.onUploadSuccess(id);            
           // this.setState({ Id: id, isRedirectDoc: true });
        })
        .catch(error => {            
            if (error.response && error.response.status == 400) {
                error.response.data.message.map(p => {
                    setError(p);
                })
            }
            else {
                setError("Failed to connect to server")
            }


            props.onUploadError();            
        });

    };

    return (
        <div className="file-uploader">
            <input type="file" onChange={handleFileInput} />
            {/* <button onClick={e => fileInput.current && fileInput.current.click()} className="btn btn-primary" /> */}
            {/* {messages.length > 0 && */}
            {error.length > 0 &&
             <div style={{color : 'red'}}> {error}</div>
             }

            {message.length > 0 &&
             <div style={{color : 'green'}}> {message}</div>
             }
        </div>
    )
}

export default FileUploader