import React, { Component } from 'react'
import Config from '../../Constants/index'


export default class UserDefaultCustomer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            customerTitle: "-",
            customerRef: -1
        };

        this.onBind = this.onBind.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    onBind = (id) => {
        this.props.onBind(id);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        fetch(Config.API_URL + "Customer/GetDefaultCustomerOfUser/?timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(customer => {
                debugger;
                this.setState({ customerRef: customer.id, customerTitle: customer.name });
                this.onBind(customer.id);
            });
    }


    render() {
        return (
            <input type="text" class="form-control" readOnly name={this.props.name} value={this.state.customerTitle} disabled />
        )
    }
}
