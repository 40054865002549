import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getIdToken } from "firebase/auth";


export default class Division extends Component {

    constructor() {
        super();
        this.state = { buildingLists: [], messages: [] };
        this.fetchData = this.fetchData.bind(this);                
        this.PAGE_TITLE = "Division";
    }

    fetchData() {
        this.setState({ loading: true });
        getIdToken(this.props.user).then((idToken)=> { 
            fetch(Config.API_URL + "Division/GetDivisionList/?timestamp=" + new Date().getTime(),
                { headers: { 'Authorization': 'Bearer ' + idToken } })
                .then(response => response.json())
                .then(json => {
                    this.setState({ buildingLists: json});
                });
        });

    }

    componentWillMount() {
        this.fetchData()
    }

    componentDidMount() {
        document.title = this.PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }

    delete() {

        let messages = [];
        let self = this;
                
        getIdToken(this.props.user).then((idToken)=> { 
            Axios.post(Config.API_URL + "UnitOfMeasurment/delete",
                {
                    Id: self.state.buildingRef,
                },
                { headers: { 'Authorization': 'Bearer ' + idToken } })
                .then(response => {
                    //let id = response.data;
                    this.setState({ deleteDialog: false });
                    this.fetchData();
                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messages.push(p);
                        })
                    }
                    else {
                        messages.push("Failed to connect to server")
                    }
                    self.setState({ messages, alertDialog: true });
                });
         });

    }


    render() {

        return (

            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                {/* Navbar */}
                <NavBar />
                <div className="container-fluid py-4">


                    <div className="col-12">
                        <div className="card mb-4">
                            <div class="card-header p-3 pt-2">
                                <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                    <i class="fa fa fa fa-sitemap fa-fw fa-fw" aria-hidden="true"></i>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 class="mb-0">{this.PAGE_TITLE}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-0 pt-0 pb-2">
                                <Grid style={{ height: '500px' }} data={this.state.buildingLists}  >
                                    <Column field="id" title="Id" width="80px" />
                                    <Column field="title" title="Title" width="300px" />
                                    <Column field="preFixChar" title="PreFix" width="100px" />
                                </Grid>   
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        )
    }
}
