
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default function CellButtonResult(select) {
    return class extends GridCell {
        render() {
            return (
                <td>
                    {this.props.dataItem.vHasDriveItem &&
                        <button class="k-button k-flat k-button-icontext" onClick={() => { select(this.props.dataItem); }}>
                            <i class="fas fa-download" style={{"margin-right": "5px"}}></i>Download</button>                                                                    
                    }
                </td>
            );
        }
    };
} 