import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';

export default class Lang extends Component {

    constructor() {
        super();
        this.state = { list: [], loading: true, messages: [] };
        this.fetchData = this.fetchData.bind(this);        
        this.PAGE_TITLE = "Language"; 
    }

    fetchData() {
        this.setState({ loading: true });
        fetch(Config.API_URL + "Lang/GetLangList/?timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                this.setState({ list: json, loading: false });
            });
    }

    componentWillMount() {
        this.fetchData()
    }

    componentDidMount() {
        document.title = this.PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }
 
    render() {


        return (


            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

            {/* Navbar */}
            <NavBar />
            <div className="container-fluid py-4">


                <div className="col-12">


                    <div className="card mb-4">
                        <div className="card-header pb-0 p-3">
                            <h6>{this.PAGE_TITLE}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <div className="table-responsive p-0">
                                <table className="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>                                                
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                          this.state.list.map((item) =>
                                            <tr>
                                            <td>
                                                <div className="d-flex px-3 py-1">
                                                    {/* <div>
                                                        <img src="../../assets/img/team-1.jpg" className="avatar me-3" alt="avatar image" />
                                                    </div> */}
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">{item.title}</h6>
                                                        <p className="text-sm font-weight-normal text-secondary mb-0">
                                                            <span className="text-success font-weight-bold">{item.preFixChar}</span>
                                                            {/* <span className="text-success font-weight-bold">{item.id}</span>{item.preFixChar} */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>                                                
                                        </tr>

                                          )
                                        }

                            
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>



                </div>




            </div>

            <Footer />
        </main>
        )


    }
}
