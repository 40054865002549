import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getIdToken } from "firebase/auth";

export default class EngDocType extends Component {

    constructor() {
        super();
        this.state = { buildingLists: [], loading: true, messages: [] };
        this.fetchData = this.fetchData.bind(this);        
        this.PAGE_TITLE = "Eng Document Types";
    }

    fetchData() {

        getIdToken(this.props.user).then((idToken)=> { 
            fetch(Config.API_URL + "EngDocType/GetEngDocTypeList/?timestamp=" + new Date().getTime(),
                { headers: { 'Authorization': 'Bearer ' + idToken} })
                .then(response => response.json())
                .then(json => {
                    this.setState({ buildingLists: json});
                });
        });

    }

    componentWillMount() {
        this.fetchData()
    }

    componentDidMount() {
        document.title = this.PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }

    render() {

        if (this.state.isRedirectToMatchOrderAdmin) {
            return <Redirect to={'/Town_Unit/' + this.state.buildingRef} />
        }

        if (this.state.isRedirecToInsert) {
            return <Redirect to={'/UnitOfMeasurment/Add'} />
        }

        if (this.state.isRedirectToEdit) {
            return <Redirect to={'/UnitOfMeasurment/Edit/' + this.state.buildingRef} />
        }

        return (
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                {/* Navbar */}
                <NavBar />
                <div className="container-fluid py-4">
                    <div className="col-12">
                        <div className="card mb-4">
                            <div class="card-header p-3 pt-2">
                                <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                    <i class="fa fa fa fa-layer-group fa-fw fa-fw" aria-hidden="true"></i>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 class="mb-0">{this.PAGE_TITLE}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-0 pt-0 pb-2">
                                <Grid style={{ height: '500px' }} data={this.state.buildingLists}  >
                                    <Column field="id" title="Id" width="80px" />
                                    <Column field="title" title="Title" width="300px" />
                                    <Column field="preFixChar" title="PreFix" width="100px" />
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        )


    }
}
