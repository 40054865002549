import React, { Component } from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import './App.css';
import '@progress/kendo-theme-default/dist/all.css';
import Layout from './Layout'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider } from "firebase/auth";
import Login from './Pages/Login';
import { getAuth, onAuthStateChanged,getIdToken } from "firebase/auth";

import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
//import Loading from './loading';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD31kFbqxOZ0Qo8_x2pTHD1yI1PgFkOFzs",
  authDomain: "cecbpm-33900.firebaseapp.com",
  projectId: "cecbpm-33900",
  storageBucket: "cecbpm-33900.appspot.com",
  messagingSenderId: "886631643251",
  appId: "1:886631643251:web:98b8ee61588a57c59f5206",
  measurementId: "G-X141Z6SX0R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const provider = new GoogleAuthProvider();

provider.addScope('https://www.googleapis.com/auth/contacts.readonly');



//debugger;
//let foo = getIdTokenPromise();
//debugger;

class App extends Component {
  render() {
    
    //const isProduction = process.env.NODE_ENV === 'production';

    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        //loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      >

      <BrowserRouter>
          <Layout />          
      </BrowserRouter>

    </CacheBuster>



    )
  }
}

export default App;