import React, { Component } from 'react'
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import t from '../../Lang/Index'
import Alert from '../../Component/Alert'

export default class RegisterForm extends Component {
    constructor() {
        super();
        this.state = {
            isInsert: true,
            firstName: '',
            lastName: '',
            mobileNumber: '',
            email: '',
            password: '',
            messages: []
        };

        this.onClick_Btn = this.onClick_Btn.bind(this);
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onClick_Btn = (e) => {
        e.preventDefault();

        if (!this.validate())
            return;

        var messages = [];
        this.setState({ messages, isInsert: false });

        var self = this;

        axios.post(Config.API_URL + 'auth/Register/'
            , {
                FirstName: this.state.firstName,
                Lastname: this.state.lastName,
                MobileNumber: this.state.mobileNumber,
                Email: this.state.email,
                Password: this.state.password
            }
        ).then(function (response) {
            window.localStorage.setItem('apiToken', response.data.token);
            self.setState({ isRedirectToHome: true });
        })
            .catch(function (error) {

                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    //messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
                    messages.push("Failed to connect to server")
                }
                console.log(error);
                self.setState({ messages, isInsert: true });

            });
    }

    validate = () => {
        var messages = [];
        if (this.state.firstName.length == 0)
            messages.push("ّFirst name is required!");

        if (this.state.lastName.length == 0)
            messages.push("Last name is required!");

        if (this.state.email.length == 0)
            messages.push("Email is required!");

        if (this.state.email.length > 0 && !isEmail(this.state.email))
            messages.push("Verify email address");

        if (this.state.password == 0)
            messages.push("Password is required!");

        this.setState({ messages: messages });

        return messages.length == 0;
    }

    render() {

        if (this.state.isRedirectToHome) {
            return <Redirect to={'/'} />
        }

        return (
            <form role="form" id="RegisterForm">
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                        First Name
                    </label>
                    <input type="text" className="form-control" id="FirstName" name="firstName" onChange={this.onChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                        Last Name
                    </label>
                    <input type="text" className="form-control" id="LastName" name="lastName" onChange={this.onChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                        Email
                    </label>
                    <input type="text" style={{ direction: 'ltr' }} className="form-control" id="Email" name="email"
                        onChange={this.onChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">
                        Password
                    </label>
                    <input type="password" style={{ direction: 'ltr' }} className="form-control" id="Password" name="password"
                        onChange={this.onChange} />
                </div>

                {this.state.messages.length > 0 &&
                    <Alert messages={this.state.messages}></Alert>}

                {this.state.isInsert ?
                    (<div>
                        <button className="btn btn-lg btn-primary btn-block" id="btnLogin" type="button"
                            onClick={this.onClick_Btn}>{t.register}</button>
                        {/* <a href={"https://accounts.google.com/o/oauth2/auth?response_type=code&redirect_uri=" + Config.google_redirect_uri +
                        "&scope=https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile" +
                        "&client_id=" + Config.google_client_id} class="btn btn-google btn-user btn-block">
                        <i class="fab fa-google fa-fw"></i> ثبت نام با حساب گوگل
                            </a> */}
                    </div>
                    )
                    : (
                        <button className="btn btn-lg btn-primary btn-block disabled" type="button"
                        >
                            <i class='fa fa-spinner fa-spin '></i> {t.loading}</button>)
                }

            </form>)
    }
}