import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert';
import CustomerLkp from '../../Component/CustomerLkp';
import EquipmentLkp from '../../Component/EquipmentLkp';
import UserLkp from '../../Component/UserLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import moment from 'moment';
import DocTypeLkp from '../../Component/DocumentControl/DocTypeLkp';
import LangLkp from '../../Component/DocumentControl/LangLkp'
import CompanyLkp from '../../Component/DocumentControl/CompanyLkp'
import DivisionLkp from '../../Component/DocumentControl/DivisionLkp';
import FileUploader from "../../Component/DocumentControl/FileUploader";
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getIdToken } from "firebase/auth";
import DocLkp from './PoDocLkp';

export default class PoDoc_Add extends Component {

    constructor(props) {
        super(props);

        let id = 0;
        let isEdit = false;

        const currentYear = new Date().getFullYear();

        this.state = {
            Id: id, isEdit, Title: '', Short: '', isRedirectDoc: false,
            DivisionRef: 0,
            messages: [],
            DivisionPreFix: '***',
            RevisionDate: null,
            RevisionNumber: 0,
            FileTblId: 0,
            ROOT_PREFIX: 'PO-CECUS',
            DocYear: currentYear,
            VendorName:'',
            ReferenceNumber:'',
            PoDesc:'',
        };

        this.PAGE_TITLE = "New Po Doc Code";

        this.onChange = this.onChange.bind(this);
        this.cancel = this.cancel.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.addEdit = this.addEdit.bind(this);
        this.edit = this.edit.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.onParentChange = this.onParentChange.bind(this);
        this.onDivisionChange = this.onDivisionChange.bind(this);
        this.onDocTypeChange = this.onDocTypeChange.bind(this);
        this.onLangChange = this.onLangChange.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        this.onUploadError = this.onUploadError.bind(this);
        this.onRevisionNumberChange = this.onRevisionNumberChange.bind(this);
    }

    onChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    
    onRevisionNumberChange(event) {
        const target = event.target;

        this.setState({
            RevisionNumber: parseInt(target.value)
        });       
    }

    onCompanyChange(event, preFix) {
        const target = event.target;
        const value = parseInt(target.value);
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.setState({ [name]: value });
        this.setState({ CompanyPreFix: preFix });
    }

    onParentChange(event) {
        const target = event.target;
        const value =  parseInt(target.value);
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.setState({ [name]: value });        
    }

    onDivisionChange(event, preFix) {
        const target = event.target;
        const value =  parseInt(target.value);
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.setState({ [name]: value });
        this.setState({ DivisionPreFix: preFix });
    }

    onDocTypeChange(event, preFix) {
        const target = event.target;
        const value =  parseInt(target.value);
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.setState({ [name]: value });
        this.setState({ DocTypePreFix: preFix });
    }

    onLangChange(event, preFix) {
        const target = event.target;
        const value =  parseInt(target.value);
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.setState({ [name]: value });
        this.setState({ LangPreFix: preFix });
    }

    cancel = () => {
        this.setState({ isRedirectDoc: true })
    }

    fetchData() {    


        Axios.get(Config.API_URL + "CertificateOfCalibration/GetCertificateOfCalibration/?id=" + this.state.Id + "&timestamp=" + new Date().getTime(),
            { headers: { 
                'Authorization': 'Bearer ' + this.props.user.accessToken }
            })
            .then(response => {
                //TODO
                //this.setState({ Title: response.data.title });              
            });
    }

    componentDidMount() {
        if (this.state.isEdit)
            this.fetchData();
    }

    addEdit() {
        if (this.state.isEdit)
            this.edit();
        else
            this.add();
    }

    add() {
        let messages = [];
        let self = this;
        debugger;

        getIdToken(this.props.user).then((idToken)=> { 


        Axios.post(Config.API_URL + "PoDoc/AddFullPoDoc",
            {
                DivisionRef:this.state.DivisionRef,
                RevisionDate: this.state.RevisionDate,
                RevisionNumber: this.state.RevisionNumber,
                VendorName: this.state.VendorName,
                ReferenceNumber: this.state.ReferenceNumber,
                PoDesc: this.state.PoDesc
            },
            { headers: { 'Authorization': 'Bearer ' + idToken } })
            .then(response => {
                let id = response.data;
                this.setState({ Id: id, isRedirectDoc: true });
            })
            .catch(error => {
                debugger;
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    messages.push("Failed to connect to server")
                }
                self.setState({ messages, isInsert: true });
            });
        });        
    }

    edit() {

        let messages = [];
        let self = this;
        debugger;

        Axios.post(Config.API_URL + "Town_Unit/edit",
            {
                Id: this.state.Id,
                Title: this.state.Title
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                let id = response.data;
                this.setState({ Id: id, isRedirectUnit: true });
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    //messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
                    messages.push("Failed to connect to server")
                }
                self.setState({ messages, isInsert: true });
            });
    }

    onUploadSuccess = (fileTblId) => {
        this.setState({ FileTblId: fileTblId });
    };

    onUploadError = () => {
        this.setState({ FileTblId: 0 });
    };

    render() {
        if (this.state.isRedirectDoc) {
            return <Redirect to={"/PoDoc/"}></Redirect>
        }

        return (
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                {/* Navbar */}
                <NavBar />
                <div className="container-fluid py-4">
                    <div className="col-lg-9 col-12 mx-auto position-relative">
                        <div className="card">
                            <div className="card-header p-3 pt-2">
                                <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                    <i class="fa fa fa-file-alt fa-fw" aria-hidden="true"></i>
                                </div>
                                <h6 className="mb-0">New PO Document</h6>
                            </div>
                            <div className="card-body pt-2">
                                <div className="row">
                                    <div className="col-md-6 mb-md-0 mb-4">
                                        <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                                            <h6 className="mb-0">
                                                {this.state.ROOT_PREFIX + '-' + this.state.DivisionPreFix + '-' +
                                                    this.state.DocYear + "-####"}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-2">
                                <div class="input-group input-group-static mb-4">
                                    <label for="exampleFormControlSelect1" class="ms-0">Division</label>
                                    <DivisionLkp name="DivisionRef" user={this.props.user} onChange={this.onDivisionChange} value={this.state.DivisionRef} />
                                </div>

                                <div className="row"> 
                                    <div className="col-12">
                                        <div className="input-group input-group-static">
                                            <label>Vendor Name</label>
                                            <input type="text" class="form-control" name="VendorName" onChange={this.onChange} value={this.state.VendorName} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row"> 
                                    <div className="col-12">
                                        <div className="input-group input-group-static">
                                            <label>Reference Number</label>
                                            <input type="text" class="form-control" name="ReferenceNumber" onChange={this.onChange} value={this.state.ReferenceNumber} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row"> 
                                    <div className="col-12">
                                        <div className="input-group input-group-static">
                                            <label>Po Descripton</label>
                                            <input type="text" class="form-control" name="PoDesc" onChange={this.onChange} value={this.state.PoDesc} />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div className="input-group input-group-static my-3">
                                            <label>Revision Date</label>
                                            <input type="date" className="form-control" name="RevisionDate" onChange={this.onChange} value={this.state.RevisionDate} />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div className="input-group input-group-static my-3">
                                            <label>Revision Number</label>
                                            <input type="number" className="form-control" name="RevisionNumber" min="0" step="1" onChange={this.onRevisionNumberChange} value={this.state.RevisionNumber} />
                                        </div>
                                    </div>
                                </div>
                              
                                {this.state.messages.length > 0 &&
                                    <Alert messages={this.state.messages}></Alert>}
                                <div className="d-flex justify-content-end mt-4">
                                    <button type="button" class="btn btn-light m-0" onClick={this.cancel}>
                                        Cancel
                                    </button>
                                    <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={this.addEdit}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        )
    }
}
