const Config = {
    //API_URL: "http://localhost:5000/",
    API_URL: "https://apibpm.citadel-engineering.com/",
    //API_URL :  "http://ec2-3-22-177-197.us-east-2.compute.amazonaws.com/",
    //API_URL :  "http://ec2-18-194-182-154.eu-central-1.compute.amazonaws.com/",
    

    //API_URL : "https://h55jk9s9t1.execute-api.us-east-2.amazonaws.com/prod",      
    //API_URL : "https://dpdi4lbbkg.execute-api.us-east-2.amazonaws.com/prod/",
    
    SITE_TITLE: "CITADEL BPM",
    SITE_PANEL_ICON: "far fa-building",
    APP_TITLE: "Control Panel ", 
    APP_BRAND: "Citadel IT Department",
    APP_BRAND_URL: "https://www.citadel-engineering.com/",

    UPLOAD_IMAGE1_WITDTH : 1024,
    UPLOAD_IMAGE1_HEIGHT : 768,
    UPLOAD_THUMB1_WITDTH : 300,
    UPLOAD_THUMB1_HEIGHT : 200,        
    //google_redirect_uri : "http://cp.alborzbasketball.com/googlecallback/",    
    //google_redirect_uri : "http://localhost:3000/googlecallback/",
    //google_client_id : "136218936220-dtmbh8bnhit879jqpoq3lmg4ib68s8of.apps.googleusercontent.com"    
}


export default Config;