import React from 'react'
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'

function LoginByGoogle () {

    const [isRedirectToHome, setisRedirectToHome] = React.useState(false);

    const onClick_BtnGoogleLogin = (e) => {
        e.preventDefault();
        const auth = getAuth();

        debugger;
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

        //signInWithRedirect(auth, provider);

        signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          debugger;
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = result._tokenResponse.idToken;
          // The signed-in user info.
          const user = result.user;

          debugger;

          Axios.post(Config.API_URL + "Auth/AddUser",
          {
              ProviderId: result.providerId,
              Uid: result.user.uid,
              Email : result.user.email
          },
          { headers: { 'Authorization': 'Bearer ' + token } })
          .then(response => {
              setisRedirectToHome(true);
          })
          .catch(error => {
  
          });




          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });

    }

    if (isRedirectToHome)
        return (<Redirect to={'/'} />)

    return (        
        <div className="text-center">                    
            <button type="button" className="btn btn-lg bg-gradient-danger btn-lg w-100 mt-4 mb-0" onClick={onClick_BtnGoogleLogin} >
                <i className="fab fa-google fa-fw" /> Sign In with Google
            </button>
        </div>
    );
};

export default LoginByGoogle;