import React, { Component } from 'react'
import t from '../Lang/Index'
import { Redirect, Link } from 'react-router-dom'
import Alert from '../Component/Alert'
import Axios from 'axios'
import Config from '../Constants/index'

export default class ChangePassword extends Component {

    constructor() {
        super();
        this.PAGE_TITLE = t.change_password;
        this.state = {
            isRedirectCancel: false,
            newPassword: '',
            currentPassword: '',
            messages: []
        };

        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.validate = this.validate.bind(this);
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    validate = () => {
        let messages = [];

        if (this.state.newPassword == "")
            messages.push("Please enter new password");

        if (this.state.currentPassword == "")
            messages.push("Please enter current password");

        this.setState({ messages });

        let result = messages.length == 0;
        return result;
    }

    save = () => {

        if (!this.validate())
            return;

        var self = this;
        var messages = [];
        this.setState({ messages });


        Axios.post(Config.API_URL + "Auth/ChangePassword", {
            NewPassword: this.state.newPassword,
            CurrentPassword: this.state.currentPassword
        },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                this.setState({ isSuccess: true });
                window.localStorage.setItem("apiToken", "");
            })
            .catch(function (error) {

                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
                }
                console.log(error);
                self.setState({ messages, isInsert: true });

            });
    }

    cancel = () => {
        this.setState({ isRedirectCancel: true })
    }

    render() {

        if (this.state.isRedirectCancel)
            return <Redirect to="/" />

        if (this.state.isSuccess) {
            return <div className="alert alert-dismissable alert-success">
                <h4>Success!</h4>
                    Go to the login page and login with new password.
                <br />
                <Link to="/Login">{t.login}</Link>
            </div>
        }

        return <div>
            <div id="page-wrapper">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">{t.dashboard}</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {this.PAGE_TITLE}
                        </li>
                    </ol>
                </nav>

                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-8">
                            <form role="form">
                                <div className="form-group" style={{ 'direction': 'ltr' }}>
                                    <label for="exampleSelect1">{t.current_password}</label>
                                    <input type="password" class="form-control" id="currentPassword" name="currentPassword"
                                        onChange={this.onChange} value={this.state.currentPassword} />
                                </div>
                                <div className="form-group" style={{ 'direction': 'ltr' }}>
                                    <label for="exampleSelect1">{t.new_password}</label>
                                    <input type="password" class="form-control" id="newPassword" name="newPassword"
                                        onChange={this.onChange} value={this.state.newPassword} />
                                </div>
                                {this.state.messages.length > 0 &&
                                    <Alert messages={this.state.messages}></Alert>}
                                <div className="from-group" style={{ 'padding-top': '10px' }}>
                                    <button type="button" className="btn btn-primary" onClick={this.save}>
                                        {t.apply}
                                    </button>
                                    <button type="button" className="btn btn-secondary" style={{ 'margin-left': '5px' }} onClick={this.cancel}>
                                        {t.cancel}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
