const t_en = {
    login : "Login",
    signIn : "Sign In",
    signUp : "Sign Up",
    email: "Email",
    password:"Password",
    loading : "Loading...",
    create_an_account : "Sign Up",
    forgot_password : "Forgot Password?",
    already_have_an_account : "Already have an account? Login!",
    register : "Register",
    forgot_your_password : "Forgot Your Password",
    reset_password : "Reset Password",
    change_password : "Change Password",
    login_with_Google : "Login By Google",
    login_with_Microsoft : "Login By Microsoft",
    dashboard : "Dashboard",
    logout : "Logout",
    current_password: "Current Password",
    new_password : "New Password",
    apply : "Apply",
    cancel : "Cancel",
    uucEucDuc : "UUC-EUC-DUC",
    cecId : "CEC-ID",
    unitOfMeasurment : "Unit of Measurement",
    reportTemplateRef : "Report Template",
    calibrationProcedureRef : "Calibration Procedure",
}

export default t_en;