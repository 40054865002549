import React, { Component } from 'react'
import Config from '../Constants/index'

export default class UserLkp extends Component {

    constructor(props) {
        super(props);
        //this.add = this.add.bind(this);
        let id = 0;
        let isEdit = false;
        /* 
        if (this.props.match.params.Id) {
            id = this.props.match.params.Id;
            isEdit = true;
        }
        */

        /* name="UserRef" onChange={this.onChange} value={this.state.UserRef} */
        //let buildingRef = this.props.match.params.buildingRef;

        this.state = {
            //TODO
            Id: id,
            isEdit: false, 
            Title: '', 
            Short: '', 
            isRedirectEquipment: false,
            CalibrationTypeRef: 1,
            UserRef:-1,
            CalibrationTypes :[{Id:1,Title:"Pressure"}],
            messages: [], 
            users: []
        };

        this.onChange = this.onChange.bind(this);
        this.fetchUsers = this.fetchUsers.bind(this);
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.props.onChange(e);
    }

    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers() {
        this.setState({ loading: true });
        fetch(Config.API_URL + "Auth/GetUsers/?timestamp=" + new Date().getTime(),
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => response.json())
            .then(json => {
                this.setState({ users: json, loading: false });
                console.log(this.state.users);
            });
    }

    render() {
        return (
            <div>
            <select class="form-control" id="exampleSelect1" name={this.props.name} 
                onChange={this.onChange} >
                <option value={0}>-</option>
                {
                    this.state.users.map(function(item){
                        return <option  value={item.id}>{item.fullName}</option>
                    })
                }
            </select>
            </div>        
        )
    }
}
